import { PUBLISHER, ADMIN, MANAGER, ADVERTISER, MANAGER_DSP } from '@/constants/user-roles'

export const admin = [{ action: 'manage', subject: 'all' }]

export const manager = [
  { action: 'read', subject: 'advertisers-list' },
  { action: 'read', subject: 'advertisers-filter' },
  { action: 'read', subject: 'advertiser-name' },
  { action: 'create', subject: 'users-table' },
  { action: 'read', subject: 'publishers'},
  { action: 'read', subject: 'platforms' },
  { action: 'read', subject: 'placements' },
  { action: 'manage', subject: 'placements-cpm'},
  { action: 'read', subject: 'placements-list'},
  { action: 'read', subject: 'publisher-name' },
  { action: 'manage', subject: 'disable-placements' },
  { action: 'manage', subject: 'advertising-campaigns-placements' },
  { action: 'read', subject: 'manager-statistic' },
  { action: 'create', subject: 'publisher' },
  { action: 'read', subject: 'publishers-list' }
]

export const managerDSP = [
  { action: 'read', subject: 'advertisers' },
  { action: 'read', subject: 'advertisers-list' },
  { action: 'read', subject: 'advertisers-filter' },
  { action: 'read', subject: 'advertiser-name' },
  { action: 'read', subject: 'advertising-campaigns' },
  { action: 'manage', subject: 'advertising-campaigns' },
  { action: 'create', subject: 'advertising-campaigns' },
  { action: 'manage', subject: 'campaign-weight' },
  { action: 'read', subject: 'dsp-statistic' }
]

export const publisher = [
  { action: 'read', subject: 'platforms' },
  { action: 'read', subject: 'placements' },
  { action: 'read', subject: 'publisher-statistic' },
  { action: 'manage', subject: 'disable-placements' }
]

export const advertiser = [
  { action: 'read', subject: 'advertising-campaigns' },
  { action: 'create', subject: 'advertising-campaigns' },
  { action: 'read', subject: 'advertiser-statistic' }
]

export function getUserAbilities(roleId) {
  switch (roleId) {
    case ADMIN:
      return admin
    case MANAGER:
      return manager
    case MANAGER_DSP:
      return managerDSP
    case PUBLISHER:
      return publisher
    case ADVERTISER:
      return advertiser
    default:
      return []
  }
}
